<template>
    <v-container fluid class="fill-height text-center mt-0 pt-0">
        <v-card class="fill-height container--fluid" width="100vw" flat>
            <v-row>
                <v-col cols="12" class="align-center justify-center">
                    <v-card :loading="loadingData">
                        <v-form ref="form" v-model="valid" lazy-validation action="/admin/wireframe">
                            <v-card-title class="primary text-h6 grey lighten-2">
                                <span class="headline white--text">{{ formTitle }}</span>
                            </v-card-title>
                            <v-container fluid class="text-center">
                                <v-row class="mt-0">
                                    <v-col cols="12">
                                        <v-textarea
                                            dense
                                            v-model="editedItem.title"
                                            label="Título"
                                            :rules="textRules"
                                            no-resize
                                            rows="2"
                                            counter
                                            required>
                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                            dense
                                            v-model="editedItem.content"
                                            label="Contenido"
                                            :rules="textRules"
                                            no-resize
                                            rows="3"
                                            counter
                                            required>
                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row no-gutters align="center" justify="center">
                                            <v-img
                                                :src="imagePreview != null ? imagePreview : require('@/assets/images/no-image.jpg')"
                                                :lazy-src="require('@/assets/images/no-image.jpg')"
                                                max-height="250"
                                                max-width="450"
                                                :aspect-ratio="1"
                                                class="white--text align-center justify-center elevation-2">
                                            </v-img>
                                        </v-row>
                                        <v-row no-gutters align="center" justify="center">
                                            <v-file-input
                                                v-model="imageFile"
                                                accept="image/png, image/jpeg, image/bmp"
                                                show-size
                                                clearable
                                                :rules="imageRules"
                                                label="Cargar/Cambiar imágen"
                                                placeholder="Archivo de imágen"
                                                prepend-icon="mdi-camera"
                                                @change="changePreviewImage()">
                                            </v-file-input>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn :disabled="!valid" color="primary" @click="save">
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <v-snackbar
            v-model="snackBar"
            :timeout="2000">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn v-bind="attrs"
                       icon
                       @click="snackBar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
    import valuesService from "@/providers/ValuesService";
    
    export default {
        name: "ValuesModuleComponent",
        data: () => ({
            snackBar: false,
            snackText: '',
            focus: false,
            valid: true,
            loadingData: false,
            imageFile: null,
            imagePreview: null,
            textRules: [
                v => !!v || 'Los datos de este campo son obligatorio!'
            ],
            emailRules: [
                v => !!v || "El Correo Electrónico es obligatorio",
                v => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido"
            ],
            imageRules: [
                value => !value || value.size < 2000000 || 'El tamaño de la imagen no debe de sobrepasar los 2 MegaBytes!'
            ],
            facebookRules: [
                f => !f ? true : this.regexMatchesText('facebook', f) || 'Enter a valid facebook url'
            ],
            editedIndex: -1,
            editedItem: {
                id: 0,
                title: "",
                content: "",
                image_url: ""
            },
            defaultItem: {
                id: 0,
                title: "",
                content: "",
                image_url: ""
            }
        }),
        computed: {
            formTitle() {
                return "Valores";
            }
        },
        methods: {
            getValues() {
                this.loadingData = true;
                valuesService.getViewData().then(record => {
                    // console.log("record: ", record.value);
                    if (record.value !== null) {
                        this.editedItem = record.value;
                        this.editedIndex = this.editedItem.id;
                        if (this.editedItem.image_url !== null) {
                            this.imagePreview = this.editedItem.image_url;
                        } else {
                            this.imagePreview = null;
                        }
                        this.imageFile = null;
                    } else {
                        this.editedItem = this.defaultItem;
                        this.newItem();
                    }
                    this.loadingData = false;
                });
            },
            newItem() {
                this.editedIndex = -1;
                this.imagePreview = null;
                this.imageFile = null;
                this.valid = false;
            },
            save() {
                if (this.imagePreview == null) {
                    // console.log('El formulario no contiene un logo asociado!!');
                    this.messageError = 'El formulario no contiene una imagen asociada. Por favor agrege una!!';
                    this.dialogError = true;
                    return;
                }
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    if (this.editedIndex > -1) {
                        // Actualizar el registro por el metodo PUT
                        valuesService.updateRecord(this.editedItem, this.imageFile).then(result => {
                            if (result.success) {
                                valuesService.getViewData();
                                this.getMision();
                            }
                        });
                    } else {
                        // Agrega el registro por el metodo POST
                        valuesService.addRecord(this.editedItem, this.imageFile).then(result => {
                            if (result.success) {
                                valuesService.getViewData();
                                this.getMision();
                            }
                        });
                    }
                    this.snackText = 'Datos del formulario guardado con éxito...';
                    this.snackBar = true;
                }
            },
            changePreviewImage() {
                if (this.imageFile) {
                    // console.log('Data imagen: ', this.imageFile, '\n');
                    if (this.imageFile.size > 2000000) {
                        this.imagePreview = null;
                        return;
                    }
                    let reader = new FileReader();
                    reader.onload = () => {
                        this.imagePreview = reader.result;
                        // // console.log('Preview imagen: ',this.imagePreview, '\n');
                    };
                    reader.readAsDataURL(this.imageFile);
                    // console.log('Imagen actualizada: ', this.imageFile.name);
                    this.snackText = 'Imágen de la seccion cargada con éxito...';
                    this.snackBar = true;
                }
            },
        },
        mounted() {
            this.editedItem = this.defaultItem;
            this.getValues();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
</style>