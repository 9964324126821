<template>
    <values-module-component/>
</template>

<script>
    import ValuesModuleComponent from "@/components/admin/ValuesModuleComponent";
    
    export default {
        name: "ValuesModule",
        title: "Valores | Private",
        components: {ValuesModuleComponent}
    }
</script>

<style scoped>

</style>